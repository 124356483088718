import React from 'react';
import { navigate } from 'gatsby-link';
import Layout from '../../components/Layout';
import styles from './styles.module.scss';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error));
  };

  render() {
    return (
      <Layout>
        <form
          name="contact"
          method="post"
          action="/contact/thanks/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
          className={styles.contact}
        >
          <h2>
            Vous pouvez nous contacter par email en utilisant ce
            formulaire&nbsp;:
          </h2>
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="contact" />
          <div hidden>
            <label>
              Don’t fill this out:{' '}
              <input name="bot-field" onChange={this.handleChange} />
            </label>
          </div>
          <div className={styles.contact__line}>
            <div className={styles.contact__control}>
              <label className={styles.contact__label} htmlFor={'name'}>
                Votre nom&nbsp;:
              </label>
              <input
                className={styles.contact__input}
                type={'text'}
                name={'name'}
                onChange={this.handleChange}
                id={'name'}
                required={true}
              />
            </div>
            <div className={styles.contact__control}>
              <label className={styles.contact__label} htmlFor={'email'}>
                Votre email&nbsp;:
              </label>
              <input
                className={styles.contact__input}
                type={'email'}
                name={'email'}
                onChange={this.handleChange}
                id={'email'}
                required={true}
              />
            </div>
          </div>
          <div className={styles.contact__line}>
            <div className={styles.contact__control}>
              <label className={styles.contact__label} htmlFor={'message'}>
                Votre message&nbsp;:
              </label>
              <textarea
                className={`${styles.contact__input} ${styles.contact__textarea}`}
                name={'message'}
                onChange={this.handleChange}
                id={'message'}
                required={true}
                placeholder="Dites nous tout"
              />
            </div>
          </div>
          <div className={styles.contact__line}>
            <div className={styles.contact__control}>
              <button
                className={`${styles.contact__button} button`}
                type="submit"
              >
                Envoyer le message
              </button>
            </div>
          </div>
        </form>
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2868.317326645681!2d2.019258!3d44.0355!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12adc653d4924363%3A0xa06f69ee6f233d0!2sCapendut%2C+81130+Milhavet%2C+France!5e0!3m2!1sfr!2s!4v1431007496668"
          width="100%"
          height="450"
          style={{ border: 'none' }}
        />
      </Layout>
    );
  }
}
